<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col class="grow">
        <v-text-field
          v-if="usingVModel"
          @change="$emit('input', $event)"
          @click:clear="
            $emit('input', null);
            $emit('search');
          "
          @keyup.enter="onSearchForVModel"
          :disabled="disabled"
          :label="searchLabel"
          :value="value"
          background-color="white"
          class="search-bar"
          clearable
          color="msaBlue"
          dense
          hide-details
          light
          outlined
          single-line
        />
        <v-text-field
          v-else
          v-model="search"
          id="search-bar"
          :label="searchLabel"
          single-line
          hide-details
          clearable
          @keyup.enter="onSearch"
          @click:clear="clearSearch()"
          dense
          outlined
          background-color="white"
          light
          color="msaBlue"
          class="search-bar"
          :disabled="disabled"
          data-testid="search-bar"
        />
      </v-col>
      <v-col class="shrink">
        <v-btn
          height="40"
          class="msaBlue white--text search-bar-btn"
          @click="onSearch"
          :style="{
            background: 'linear-gradient(to bottom,  #0a5fb1 0%,#093f79 100%)',
          }"
          elevation="0"
          :disabled="disabled"
          data-test-id="search-button"
          data-testid="search-button"
        >
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/**
 * This is the standard search bar for use in CHT.
 *
 * It can either be used with v-model, or it can contain its own search text
 * state and emit it upon hitting the search button.
 *
 * @emits search on hitting the button or the enter key
 */
export default {
  name: 'SearchBar',
  props: {
    searchLabel: {
      type: String,
      default: 'Search',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /** Set to true if you want to use this component with v-model */
    usingVModel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    /** @deprecated you should use this component with v-model */
    onSearch() {
      if (this.search == null || this.search.trim().length == 0) {
        this.search = '';
      }
      this.$emit('search', this.search);
    },
    onSearchForVModel() {
      if (!this.value || this.value.trim().length == 0) {
        this.$emit('input', null);
        this.$emit('search', this.value);
        return;
      }
      this.$emit('search', this.value);
    },
    /** @deprecated you should use this component with v-model */
    clearSearch() {
      this.search = '';
      this.onSearch();
    },
    /**
     * DO NOT remove this method - it's OFTEN called externally with $refs
     * @deprecated you should use this component with v-model, and avoid
     * accessing its private methods with $refs...
     */
    setSearch(data) {
      this.search = data;
    },
  },
};
</script>
<style>
.search-bar {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.search-bar-btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
</style>
