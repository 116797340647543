<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <SearchBar
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by label..."
        />
      </v-col>
      <v-col align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="showDialog()"
            >
              <v-icon> mdi-tag </v-icon>
            </v-btn>
          </template>
          <span>Attach Labels</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :items="items"
            :server-items-length="itemsCount"
            :options.sync="options"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-blur
                    text
                    small
                    v-on="on"
                    @click="detachLabel(item.id)"
                  >
                    <v-icon color="red"> mdi-tag-off </v-icon>
                  </v-btn>
                </template>
                <span>Detach</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="attachLabelsDialog" width="500" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text"> Attach Labels </v-card-title>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12">
              <SearchBar
                ref="searchBar"
                @search="onSearchAvailable($event)"
                searchLabel="Search by label..."
              />
            </v-col>
            <v-col>
              <v-card>
                <v-data-table
                  class="colored-table-check-box"
                  show-select
                  v-model="selected"
                  :headers="headers"
                  :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
                  :items="availableItems"
                  :server-items-length="availableItemsCount"
                  :options.sync="availableItemsOptions"
                  checkbox-color="msaBlue"
                >
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="closeDialog()">cancel</v-btn>
          <v-btn
            class="msaBlue white--text"
            @click="attachLabels()"
            :disabled="selected.length == 0"
            >confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'PublicFormTemplateLabels',
  components: {
    SearchBar,
  },
  data() {
    return {
      items: [],
      itemsCount: 0,
      options: this.$helpers.defaultTableOptions('name', false),
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'lightGrey',
          sortable: false,
          width: '140px',
        },
      ],
      search: '',
      attachLabelsDialog: false,
      availableItems: [],
      availableItemsCount: 0,
      availableItemsOptions: this.$helpers.defaultTableOptions('name', false),
      availableItemsHeader: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'lightGrey',
        },
      ],
      selected: [],
      searchAvailable: '',
    };
  },
  methods: {
    getItems() {
      const params = {
        search: this.search,
        loaderText: 'loading...',
        id: this.$store.getters.selectedFormTemplate.id,
        options: this.options,
      };

      this.$axios
        .post('get-public-form-template-labels?format=json', params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.itemsCount;
        })
        .catch((error) => error);
    },
    onSearch(event) {
      this.search = event;
      this.options.page = 1;
      this.getItems();
    },
    onSearchAvailable(event) {
      this.searchAvailable = event;
      this.availableItemsOptions.page = 1;
      this.getAvailableLabels();
    },
    detachLabel(labelId) {
      const confirm = () => {
        const params = {
          loaderText: 'loading...',
          labelId,
          publicFormTemplateId: this.$store.getters.selectedFormTemplate.id,
        };
        const url = 'detach-label-from-public-form-template?format=json';
        this.$axios
          .post(url, params)
          .then(() => this.getItems())
          .catch((error) => error);
      };

      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to detach this label?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    getAvailableLabels() {
      const params = {
        search: this.searchAvailable,
        loaderText: 'loading...',
        id: this.$store.getters.selectedFormTemplate.id,
        options: this.availableItemsOptions,
      };

      return this.$axios
        .post(
          'get-available-labels-for-public-form-template?format=json',
          params,
        )
        .then((response) => {
          this.availableItems = response.data.items;
          this.availableItemsCount = response.data.itemsCount;
        })
        .catch((error) => error);
    },
    showDialog() {
      this.getAvailableLabels().then(() => (this.attachLabelsDialog = true));
    },
    closeDialog() {
      this.selected = [];
      this.attachLabelsDialog = false;
    },
    attachLabels() {
      const params = {
        loaderText: 'loading...',
        id: this.$store.getters.selectedFormTemplate.id,
        labelIds: this.selected.map((s) => s.id),
      };
      const url = 'attach-labels-to-public-form-template?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.closeDialog();
          this.getItems();
        })
        .catch((error) => error);
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style></style>
